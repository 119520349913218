import React from "react"

import { Grommet, Box, Grid, ResponsiveContext, Text } from "grommet"

import { grommet } from "grommet/themes"
import { deepMerge } from "grommet/utils"

import content from "../../content/groepslessen-2.yaml"
import { Reddit } from "grommet-icons"

const customBreakpoints = deepMerge(grommet, {
  global: {
    breakpoints: {
      small: {
        value: 600,
      },
      medium: {
        value: 900,
      },
      large: {
        value: 3000,
      },
    },
    colors: {
      unitygreen: '#c8d100',
      unitydark: '#23292F',
      FitClass: '#23292F',
      PUMP: '#23292F',
      "Color Cycling": '#23292F',
      Crosstraining: '#23292F',
      Pilates: '#23292F',
      Fatburner: '#23292F',
      FBW: '#23292F',
      STRONG: '#23292F',
      Spinning: '#23292F',
      Zumba: '#23292F',
      Bootcamp: '#23292F',
      "Abs & Legs": '#23292F',
      "Body & Mind": '#23292F'
    }
  },

})

const columns = {
  small: ["small", "small", "small", "small", "small", "small", "small", "small", "small"],
  medium: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
  large: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
  xlarge: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
}

// If the size is small, we have 3 rows
// If the size is medium, we have 2 rows
// If the size is large or xlarge, we have 1 row
const rows = {
  small: [
    "xsmall",
    "xsmall",
    "xsmall",
    "xsmall",
    "xsmall",
    "xsmall",
    "xsmall",
    "xsmall",
    "xsmall",
    "xsmall",
    "xsmall",
    "xsmall",
    "xsmall",
    "xsmall",
    "xsmall",
  ],
  medium: [
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
  ],
  large: [
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
  ],
  xlarge: [
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
    "xxsmall",
  ],
}

// Set the different areas you need for every size
const fixedGridAreas = {
  small: [
    { name: "th-hours", start: [0, 0], end: [0, 0] },
    { name: "th-maandag", start: [1, 0], end: [1, 0] },
    { name: "th-dinsdag", start: [2, 0], end: [2, 0] },
    { name: "th-woensdag", start: [3, 0], end: [4, 0] },
    { name: "th-donderdag", start: [5, 0], end: [5, 0] },
    { name: "th-vrijdag", start: [6, 0], end: [6, 0] },
    { name: "th-zaterdag", start: [7, 0], end: [7, 0] },
    { name: "th-zondag", start: [8, 0], end: [8, 0] },
    { name: "tr-1000-hours", start: [0, 1], end: [0, 1] },
    { name: "tr-1000-maandag", start: [1, 1], end: [1, 1] },
    { name: "tr-1000-woensdag", start: [3, 1], end: [4, 1] },
    { name: "tr-1000-vrijdag", start: [6, 1], end: [6, 1] },
    { name: "tr-1030-hours", start: [0, 2], end: [0, 2] },
    { name: "tr-1030-zondag", start: [8, 2], end: [8, 2] },
    { name: "tr-1100-hours", start: [0, 3], end: [0, 3] },
    { name: "tr-1100-zaterdag", start: [7, 3], end: [7, 3] },
    { name: "tr-1130-hours", start: [0, 4], end: [0, 4] },
    { name: "tr-1130-zaterdag", start: [8, 4], end: [8, 4] },
    { name: "tr-1245-hours", start: [0, 5], end: [0, 5] },
    { name: "tr-1800-hours", start: [0, 6], end: [0, 6] },
    { name: "tr-1800-maandag", start: [1, 6], end: [1, 6] },
    { name: "tr-1815-hours", start: [0, 7], end: [0, 7] },
    { name: "tr-1815-dinsdag", start: [2, 7], end: [2, 7] },
    { name: "tr-1815-vrijdag", start: [6, 7], end: [6, 7] },
    { name: "tr-1830-hours", start: [0, 8], end: [0, 8] },
    { name: "tr-1830-woensdag", start: [3, 8], end: [4, 8] },
    { name: "tr-1830-donderdag", start: [5, 8], end: [5, 8] },
    { name: "tr-1900-hours", start: [0, 9], end: [0, 9] },
    { name: "tr-1900-maandag", start: [1, 9], end: [1, 9] },
    { name: "tr-1915-hours", start: [0, 10], end: [0, 10] },
    { name: "tr-1915-dinsdag", start: [2, 10], end: [2, 10] },
    { name: "tr-1915-vrijdag", start: [6, 10], end: [6, 10] },
    { name: "tr-1930-hours", start: [0, 11], end: [0, 11] },
    { name: "tr-1930-woensdagA", start: [3, 11], end: [3, 11] }, // Added A and B to cover 2 sessions at same hour
    { name: "tr-1930-woensdagB", start: [4, 11], end: [4, 11] },
    { name: "tr-1930-donderdag", start: [5, 11], end: [5, 11] },
    { name: "tr-2000-hours", start: [0, 12], end: [0, 12] },
    { name: "tr-2000-maandag", start: [1, 12], end: [1, 12] },
    { name: "tr-2015-hours", start: [0, 13], end: [0, 13] },
    { name: "tr-2015-dinsdag", start: [2, 13], end: [2, 13] },
    { name: "tr-2030-hours", start: [0, 14], end: [0, 14] },
    { name: "tr-2030-woensdag", start: [3, 14], end: [4, 14] },
  ],
  medium: [
    { name: "th-hours", start: [0, 0], end: [0, 0] },
    { name: "th-maandag", start: [1, 0], end: [1, 0] },
    { name: "th-dinsdag", start: [2, 0], end: [2, 0] },
    { name: "th-woensdag", start: [3, 0], end: [4, 0] },
    { name: "th-donderdag", start: [5, 0], end: [5, 0] },
    { name: "th-vrijdag", start: [6, 0], end: [6, 0] },
    { name: "th-zaterdag", start: [7, 0], end: [7, 0] },
    { name: "th-zondag", start: [8, 0], end: [8, 0] },
    { name: "tr-1000-hours", start: [0, 1], end: [0, 1] },
    { name: "tr-1000-maandag", start: [1, 1], end: [1, 1] },
    { name: "tr-1000-woensdag", start: [3, 1], end: [4, 1] },
    { name: "tr-1000-vrijdag", start: [6, 1], end: [6, 1] },
    { name: "tr-1030-hours", start: [0, 2], end: [0, 2] },
    { name: "tr-1030-zondag", start: [8, 2], end: [8, 2] },
    { name: "tr-1100-hours", start: [0, 3], end: [0, 3] },
    { name: "tr-1100-zaterdag", start: [7, 3], end: [7, 3] },
    { name: "tr-1130-hours", start: [0, 4], end: [0, 4] },
    { name: "tr-1130-zaterdag", start: [8, 4], end: [8, 4] },
    { name: "tr-1245-hours", start: [0, 5], end: [0, 5] },
    { name: "tr-1800-hours", start: [0, 6], end: [0, 6] },
    { name: "tr-1800-maandag", start: [1, 6], end: [1, 6] },
    { name: "tr-1815-hours", start: [0, 7], end: [0, 7] },
    { name: "tr-1815-dinsdag", start: [2, 7], end: [2, 7] },
    { name: "tr-1815-vrijdag", start: [6, 7], end: [6, 7] },
    { name: "tr-1830-hours", start: [0, 8], end: [0, 8] },
    { name: "tr-1830-woensdag", start: [3, 8], end: [4, 8] },
    { name: "tr-1830-donderdag", start: [5, 8], end: [5, 8] },
    { name: "tr-1900-hours", start: [0, 9], end: [0, 9] },
    { name: "tr-1900-maandag", start: [1, 9], end: [1, 9] },
    { name: "tr-1915-hours", start: [0, 10], end: [0, 10] },
    { name: "tr-1915-dinsdag", start: [2, 10], end: [2, 10] },
    { name: "tr-1915-vrijdag", start: [6, 10], end: [6, 10] },
    { name: "tr-1930-hours", start: [0, 11], end: [0, 11] },
    { name: "tr-1930-woensdagA", start: [3, 11], end: [3, 11] }, // Added A and B to cover 2 sessions at same hour
    { name: "tr-1930-woensdagB", start: [4, 11], end: [4, 11] },
    { name: "tr-1930-donderdag", start: [5, 11], end: [5, 11] },
    { name: "tr-2000-hours", start: [0, 12], end: [0, 12] },
    { name: "tr-2000-maandag", start: [1, 12], end: [1, 12] },
    { name: "tr-2015-hours", start: [0, 13], end: [0, 13] },
    { name: "tr-2015-dinsdag", start: [2, 13], end: [2, 13] },
    { name: "tr-2030-hours", start: [0, 14], end: [0, 14] },
    { name: "tr-2030-woensdag", start: [3, 14], end: [4, 14] },
  ],
  large: [
    { name: "th-hours", start: [0, 0], end: [0, 0] },
    { name: "th-maandag", start: [1, 0], end: [1, 0] },
    { name: "th-dinsdag", start: [2, 0], end: [2, 0] },
    { name: "th-woensdag", start: [3, 0], end: [4, 0] },
    { name: "th-donderdag", start: [5, 0], end: [5, 0] },
    { name: "th-vrijdag", start: [6, 0], end: [6, 0] },
    { name: "th-zaterdag", start: [7, 0], end: [7, 0] },
    { name: "th-zondag", start: [8, 0], end: [8, 0] },
    { name: "tr-1000-hours", start: [0, 1], end: [0, 1] },
    { name: "tr-1000-maandag", start: [1, 1], end: [1, 1] },
    { name: "tr-1000-woensdag", start: [3, 1], end: [4, 1] },
    { name: "tr-1000-vrijdag", start: [6, 1], end: [6, 1] },
    { name: "tr-1030-hours", start: [0, 2], end: [0, 2] },
    { name: "tr-1030-zondag", start: [8, 2], end: [8, 2] },
    { name: "tr-1100-hours", start: [0, 3], end: [0, 3] },
    { name: "tr-1100-zaterdag", start: [7, 3], end: [7, 3] },
    { name: "tr-1130-hours", start: [0, 4], end: [0, 4] },
    { name: "tr-1130-zaterdag", start: [8, 4], end: [8, 4] },
    { name: "tr-1245-hours", start: [0, 5], end: [0, 5] },
    { name: "tr-1800-hours", start: [0, 6], end: [0, 6] },
    { name: "tr-1800-maandag", start: [1, 6], end: [1, 6] },
    { name: "tr-1815-hours", start: [0, 7], end: [0, 7] },
    { name: "tr-1815-dinsdag", start: [2, 7], end: [2, 7] },
    { name: "tr-1815-vrijdag", start: [6, 7], end: [6, 7] },
    { name: "tr-1830-hours", start: [0, 8], end: [0, 8] },
    { name: "tr-1830-woensdag", start: [3, 8], end: [4, 8] },
    { name: "tr-1830-donderdag", start: [5, 8], end: [5, 8] },
    { name: "tr-1900-hours", start: [0, 9], end: [0, 9] },
    { name: "tr-1900-maandag", start: [1, 9], end: [1, 9] },
    { name: "tr-1915-hours", start: [0, 10], end: [0, 10] },
    { name: "tr-1915-dinsdag", start: [2, 10], end: [2, 10] },
    { name: "tr-1915-vrijdag", start: [6, 10], end: [6, 10] },
    { name: "tr-1930-hours", start: [0, 11], end: [0, 11] },
    { name: "tr-1930-woensdagA", start: [3, 11], end: [3, 11] }, // Added A and B to cover 2 sessions at same hour
    { name: "tr-1930-woensdagB", start: [4, 11], end: [4, 11] },
    { name: "tr-1930-donderdag", start: [5, 11], end: [5, 11] },
    { name: "tr-2000-hours", start: [0, 12], end: [0, 12] },
    { name: "tr-2000-maandag", start: [1, 12], end: [1, 12] },
    { name: "tr-2015-hours", start: [0, 13], end: [0, 13] },
    { name: "tr-2015-dinsdag", start: [2, 13], end: [2, 13] },
    { name: "tr-2030-hours", start: [0, 14], end: [0, 14] },
    { name: "tr-2030-woensdag", start: [3, 14], end: [4, 14] },
  ],
  xlarge: [
    { name: "th-hours", start: [0, 0], end: [0, 0] },
    { name: "th-maandag", start: [1, 0], end: [1, 0] },
    { name: "th-dinsdag", start: [2, 0], end: [2, 0] },
    { name: "th-woensdag", start: [3, 0], end: [4, 0] },
    { name: "th-donderdag", start: [5, 0], end: [5, 0] },
    { name: "th-vrijdag", start: [6, 0], end: [6, 0] },
    { name: "th-zaterdag", start: [7, 0], end: [7, 0] },
    { name: "th-zondag", start: [8, 0], end: [8, 0] },
    { name: "tr-1000-hours", start: [0, 1], end: [0, 1] },
    { name: "tr-1000-maandag", start: [1, 1], end: [1, 1] },
    { name: "tr-1000-woensdag", start: [3, 1], end: [4, 1] },
    { name: "tr-1000-vrijdag", start: [6, 1], end: [6, 1] },
    { name: "tr-1030-hours", start: [0, 2], end: [0, 2] },
    { name: "tr-1030-zondag", start: [8, 2], end: [8, 2] },
    { name: "tr-1100-hours", start: [0, 3], end: [0, 3] },
    { name: "tr-1100-zaterdag", start: [7, 3], end: [7, 3] },
    { name: "tr-1130-hours", start: [0, 4], end: [0, 4] },
    { name: "tr-1130-zaterdag", start: [8, 4], end: [8, 4] },
    { name: "tr-1245-hours", start: [0, 5], end: [0, 5] },
    { name: "tr-1800-hours", start: [0, 6], end: [0, 6] },
    { name: "tr-1800-maandag", start: [1, 6], end: [1, 6] },
    { name: "tr-1815-hours", start: [0, 7], end: [0, 7] },
    { name: "tr-1815-dinsdag", start: [2, 7], end: [2, 7] },
    { name: "tr-1815-vrijdag", start: [6, 7], end: [6, 7] },
    { name: "tr-1830-hours", start: [0, 8], end: [0, 8] },
    { name: "tr-1830-woensdag", start: [3, 8], end: [4, 8] },
    { name: "tr-1830-donderdag", start: [5, 8], end: [5, 8] },
    { name: "tr-1900-hours", start: [0, 9], end: [0, 9] },
    { name: "tr-1900-maandag", start: [1, 9], end: [1, 9] },
    { name: "tr-1915-hours", start: [0, 10], end: [0, 10] },
    { name: "tr-1915-dinsdag", start: [2, 10], end: [2, 10] },
    { name: "tr-1915-vrijdag", start: [6, 10], end: [6, 10] },
    { name: "tr-1930-hours", start: [0, 11], end: [0, 11] },
    { name: "tr-1930-woensdagA", start: [3, 11], end: [3, 11] }, // Added A and B to cover 2 sessions at same hour
    { name: "tr-1930-woensdagB", start: [4, 11], end: [4, 11] },
    { name: "tr-1930-donderdag", start: [5, 11], end: [5, 11] },
    { name: "tr-2000-hours", start: [0, 12], end: [0, 12] },
    { name: "tr-2000-maandag", start: [1, 12], end: [1, 12] },
    { name: "tr-2015-hours", start: [0, 13], end: [0, 13] },
    { name: "tr-2015-dinsdag", start: [2, 13], end: [2, 13] },
    { name: "tr-2030-hours", start: [0, 14], end: [0, 14] },
    { name: "tr-2030-woensdag", start: [3, 14], end: [4, 14] },
  ],
}


const Responsive = ({
  children,
  overrideColumns,
  overrideRows,
  areas,
  ...props
}) => (
  <ResponsiveContext.Consumer>
    {size => {
      // Take into consideration if not array is sent but a simple string
      let columnsVal = columns
      if (columns) {
        if (columns[size]) {
          columnsVal = columns[size]
        }
      }

      let rowsVal = rows
      if (rows) {
        if (rows[size]) {
          rowsVal = rows[size]
        }
      }

      // Also if areas is a simple array not an object of arrays for
      // different sizes
      let areasVal = areas
      if (areas && !Array.isArray(areas)) areasVal = areas[size]

      return (
        <Grid
          {...props}
          areas={!areasVal ? undefined : areasVal}
          rows={!rowsVal ? size : rowsVal}
          columns={!columnsVal ? size : columnsVal}
        >
          {children}
        </Grid>
      )
    }}
  </ResponsiveContext.Consumer>
)

export const ResponsiveGrid = () => (
  <section id="groepslessen">
    <div className="row section-head">
      <h1>{content.title}</h1>
      <p>{ content.body }</p>
    </div>
    <Grommet theme={customBreakpoints}>
      <Box
        overflow={{"horizontal": "auto"}}>
        <Responsive
          rows={rows}
          columns={columns}
          gap="xxsmall"
          areas={fixedGridAreas}
          margin="medium"
        >
          <Box
            gridArea="th-hours"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">hours</Text></strong>
          </Box>
          <Box
            gridArea="th-maandag"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">Maandag</Text></strong>
          </Box>
          <Box
            gridArea="th-dinsdag"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">Dinsdag</Text></strong>
          </Box>
          <Box
            gridArea="th-woensdag"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">Woensdag</Text></strong>
          </Box>
          <Box
            gridArea="th-donderdag"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">Donderdag</Text></strong>
          </Box>
          <Box
            gridArea="th-vrijdag"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">Vrijdag</Text></strong>
          </Box>
          <Box
            gridArea="th-zaterdag"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">Zaterdag</Text></strong>
          </Box>
          <Box
            gridArea="th-zondag"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">Zondag</Text></strong>
          </Box>
          <Box
            gridArea="tr-1000-hours"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">10:00</Text></strong>
          </Box>
          <Box
            gridArea="tr-1000-maandag"
            background={{"color": "FitClass"}}
            justify="center"
            align="center"
          >
            <Text color="unitygreen">FitClass <br/>
            (Barhi)</Text>
          </Box>
          <Box
            gridArea="tr-1000-woensdag"
            background={{"color": "FitClass"}}
            justify="center"
            align="center"
          ><Text color="unitygreen">
            Popup les for elderly <br/></Text>
          </Box>
          <Box
            gridArea="tr-1000-vrijdag"
            background={{"color": "FitClass"}}
            justify="center"
            align="center"
          ><Text color="unitygreen">
            FitClass  <br/>(Barhi)</Text>
          </Box>
          <Box
            gridArea="tr-1030-hours"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">10:30</Text></strong>
          </Box>
          <Box
            gridArea="tr-1100-hours"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">11:00</Text></strong>
          </Box>
          <Box
            gridArea="tr-1100-zaterdag"
            background={{"color": "Crosstraining"}}
            justify="center"
            align="center"
          ><Text color="unitygreen">
            Crosstraining<br/>(Xa4)</Text>
          </Box>
          <Box
            gridArea="tr-1130-hours"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">11:30</Text></strong>
          </Box>
          <Box
            gridArea="tr-1130-zaterdag"
            background={{"color": "Color Cycling"}}
            justify="center"
            align="center"
          ><Text color="unitygreen">
            Power Cycling <br/>(Thor)</Text>
          </Box>
          <Box
            gridArea="tr-1245-hours"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">12:45</Text></strong>
          </Box>
          <Box
            gridArea="tr-1800-hours"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">18:00</Text></strong>
          </Box>
          <Box
            gridArea="tr-1800-maandag"
            background={{"color": "FBW"}}
            justify="center"
            align="center"
          ><Text color="unitygreen">
            Full Body Workout<br/>(Nina)</Text>
          </Box>
          <Box
            gridArea="tr-1815-hours"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">18:15</Text></strong>
          </Box>
          <Box
            gridArea="tr-1815-dinsdag"
            background={{"color": "Pilates"}}
            justify="center"
            align="center"
          ><Text color="unitygreen">
            Pilates<br/>(Robin)</Text>
          </Box>
          <Box
            gridArea="tr-1815-vrijdag"
            background={{"color": "Fatburner"}}
            justify="center"
            align="center"
          ><Text color="unitygreen">
            Cardio Box<br/>(Jean-Louis)</Text>
          </Box>
          <Box
            gridArea="tr-1830-hours"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">18:30</Text></strong>
          </Box>
          <Box
            gridArea="tr-1830-woensdag"
            background={{"color": "Color Cycling"}}
            justify="center"
            align="center"
          ><Text color="unitygreen">
            Power Cycling<br/>(Thor)</Text>
          </Box>
          <Box
            gridArea="tr-1830-donderdag"
            background={{"color": "Color Cycling"}}
            justify="center"
            align="center"
          ><Text color="unitygreen">
            Power Cycling<br/>(Thor)</Text>
          </Box>
          <Box
            gridArea="tr-1900-hours"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">19:00</Text></strong>
          </Box>
          <Box
            gridArea="tr-1900-maandag"
            background={{"color": "Spinning"}}
            justify="center"
            align="center"
          ><Text color="unitygreen">
            Spinning<br/>(Nina)</Text>
          </Box>
          <Box
            gridArea="tr-1915-hours"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">19:15</Text></strong>
          </Box>
          <Box
            gridArea="tr-1915-dinsdag"
            background={{"color": "Fatburner"}}
            justify="center"
            align="center"
          ><Text color="unitygreen">
            Step Aerobic<br/>(Sofie)</Text>
          </Box>
          <Box
            gridArea="tr-1915-vrijdag"
            background={{"color": "Body & Mind"}}
            justify="center"
            align="center"
          ><Text color="unitygreen">
            Body & Mind<br/>(Barhi)</Text>
          </Box>
          <Box
            gridArea="tr-1930-hours"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">19:30</Text></strong>
          </Box>
          <Box
            gridArea="tr-1930-woensdagA"
            background={{"color": "STRONG"}}
            justify="center"
            align="center"
          ><Text color="unitygreen">
            Zumba <br/>(Elien & Robin)</Text>
          </Box>

          <Box
            gridArea="tr-1930-woensdagB"
            background={{"color": "Bootcamp"}}
            justify="center"
            align="center"
          ><Text color="unitygreen">
            Bootcamp #1 <br/>(Laura)</Text>
          </Box>

          
          <Box
            gridArea="tr-1930-donderdag"
            background={{"color": "STRONG"}}
            justify="center"
            align="center"
          ><Text color="unitygreen">
            Zumba<br/>(Sofie)</Text>
          </Box>
          <Box
            gridArea="tr-2000-hours"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">20:00</Text></strong>
          </Box>
          <Box
            gridArea="tr-2000-maandag"
            background={{"color": "Fatburner"}}
            justify="center"
            align="center"
          ><Text color="unitygreen">
            Kickbox with no sparring<br/>(Jean)</Text>
          </Box>
          <Box
            gridArea="tr-2015-hours"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">20:15</Text></strong>
          </Box>
          <Box
            gridArea="tr-2015-dinsdag"
            background={{"color": "PUMP"}}
            justify="center"
            align="center"
          ><Text color="unitygreen">
            PUMP<br/>(Barhi)</Text>
          </Box>
          <Box
            gridArea="tr-2030-hours"
            background={{"color": "unitydark"}}
            justify="center"
            align="center"
          >
            <strong><Text color="unitygreen">20:30</Text></strong>
          </Box>
          <Box
            gridArea="tr-2030-woensdag"
            background={{"color": "Bootcamp"}}
            justify="center"
            align="center"
          ><Text color="unitygreen">
            Bootcamp #2<br/>(Laura)</Text>
          </Box>
        </Responsive>
      </Box>
    </Grommet>
  </section>
)

ResponsiveGrid.storyName = "Responsive grid"

export default ResponsiveGrid
