import React from "react"
import { Helmet } from "react-helmet"
import { Grommet } from "grommet"


import { useSiteMetadata } from "../hooks/use-site-metadata"

import Header from "./header"
import Footer from "./footer"

import "../styles/default.css"
import "../styles/layout.css"
import "../styles/media-queries.css"
import "../styles/animate.css"
import "../styles/prettyPhoto.css"
import "../styles/lessen.css"

import 'typeface-open-sans';
import 'typeface-montserrat';

import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
config.autoAddCss = false

export default function Layout ({ children }){

  const { title, description, author, favicon } = useSiteMetadata()
  const theme = {
    global: {
      edgeSize: {
        responsiveBreakpoint: 'xlarge',
      },
      font: {
        family: 'Helvetica',
      },
      colors: {
        unitygreen: '#c8d100',
        FitClass: '#FF0000',
        PUMP: 'orange',
        "Color Cycling": 'red',
        Crosstraining: 'red',
        Pilates: 'red',
        Fatburner: 'red',
        FBW: 'red',
        STRONG: 'red',
        Spinning: 'red',
        Zumba: 'red',
        Bootcamp: 'red',
        "Abs & Legs": 'red'
      },
    },
    table: {
      body: {
        responsiveBreakpoint: 'large',
        align: 'center',
        pad: { horizontal: 'large', vertical: 'xsmall' },
        border: 'horizontal',
      },
      extend: () => `font-family: Helvetica; white-space: no-wrap;`,
      footer: {
        align: 'start',
        border: undefined,
        pad: { horizontal: 'large', vertical: 'small' },
        verticalAlign: 'bottom',
      },
      header: {
        align: 'center',
        border: 'bottom',
        fill: 'horizontal',
        pad: { horizontal: 'large', vertical: 'xsmall' },
        verticalAlign: 'bottom',
        background: {
          color: "unitygreen",
          opacity: 'strong',
        },
      },
    },
  };
  
  return(
    <>
    <Grommet theme={theme}>
    <Helmet>
      <title>{ title }</title>
      <meta name="description" content={ description } />
      <meta name="author" content={ author.name } />

    	{/* <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" /> */}

      <link rel="shortcut icon" href={'/' + favicon } type="image/png" />
    </Helmet>

    <Header />

  <main id="top">
    {children}
  </main>

  <Footer />
  </Grommet>
  </>
  )
}
